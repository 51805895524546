var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import './vehicleInformationModule.scss';
export function vehicleInformationModule() {
    return {
        id: Symbol('vehicleInformationModule'),
        hooks: function (context) { return ({
            vehicleInformation: function (vehicle, items) {
                return __awaiter(this, void 0, void 0, function () {
                    var sortedItems;
                    return __generator(this, function (_a) {
                        if (vehicle.ref === 'product') {
                            return [2 /*return*/, items];
                        }
                        sortedItems = [];
                        sortedItems.push(items[0]); //VIN
                        addItem(items, sortedItems, 'nmlModel');
                        addItem(items, sortedItems, 'registration');
                        sortedItems.push(items[1]); //Product Year
                        addItem(items, sortedItems, 'modelCode');
                        addItem(items, sortedItems, 'modelDescription');
                        addItem(items, sortedItems, 'vehicleMake');
                        sortedItems.push(items[6]); //Product Date
                        addItem(items, sortedItems, 'warrantyStartDate');
                        addItem(items, sortedItems, 'bodyType');
                        addItem(items, sortedItems, 'engineType');
                        addItem(items, sortedItems, 'engineIndex');
                        addItem(items, sortedItems, 'horsePower');
                        addItem(items, sortedItems, 'driveTypeLongDesc');
                        addItem(items, sortedItems, 'transLongDesc');
                        addItem(items, sortedItems, 'gearboxNumber');
                        addItem(items, sortedItems, 'gearboxIndex');
                        addItem(items, sortedItems, 'handle');
                        addItem(items, sortedItems, 'grade');
                        addItem(items, sortedItems, 'cylinder');
                        addItem(items, sortedItems, 'fuelType');
                        addItem(items, sortedItems, 'neModelVariantCode');
                        addItem(items, sortedItems, 'errorCD');
                        addItem(items, sortedItems, 'extClrDesc');
                        addItem(items, sortedItems, 'intClrDesc');
                        addItem(items, sortedItems, 'specCodes');
                        return [2 /*return*/, translateItems(sortedItems, context)];
                    });
                });
            }
        }); },
        strings: function (language) {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 4]);
                            return [4 /*yield*/, import(/* webpackChunkName: '[request]' */ "./strings/".concat(language, ".strings.ts"))];
                        case 1: return [2 /*return*/, (_b.sent()).default];
                        case 2:
                            _a = _b.sent();
                            return [4 /*yield*/, import(/* webpackChunkName: '[request]' */ './strings/en.strings')];
                        case 3: return [2 /*return*/, (_b.sent()).default];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
    };
}
function addItem(items, sortedItems, fieldName) {
    var field = items.filter(function (item) { return item.name === fieldName; })[0];
    if (field) {
        sortedItems.push(field);
    }
}
function translateItems(sortedItems, context) {
    var itemsToTranslate = new Set(Object.keys(context.strings.vehicleInformation));
    return sortedItems.map(function (item) {
        return itemsToTranslate.has(item.name) && item.name !== 'optnSpecDesc' ?
            {
                name: context.strings.vehicleInformation[item.name],
                value: item.value
            } :
            item;
    });
}
