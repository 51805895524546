var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createIwa } from "@cognitranlimited/itis-web";
import React from "react";
import { NissanDependencies } from "./NissanDependencies";
var Iwa = createIwa();
export var NissanIwa = function (props) {
    return React.createElement(NissanDependencies, null,
        React.createElement(Iwa, __assign({}, props)));
};
