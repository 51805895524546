import React from 'react';
import { useAosSubscriptionContext } from './aosSubscriptionContext';
import { Text } from "@cognitranlimited/itis-web";
import './aosSubscriptionModule.scss';
var AosSubscriptionRoute = function () {
    var strings = useAosSubscriptionContext().strings.aosSubscription;
    return React.createElement("div", { className: "AosSubscriptionContainer" },
        React.createElement(Text, { element: "p" }, strings.description.para1),
        React.createElement(Text, { element: "p" }, strings.description.para2),
        React.createElement(Text, { element: "p" }, strings.description.para3),
        React.createElement(Text, { element: "p" }, strings.description.para4));
};
export default AosSubscriptionRoute;
