import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { Loader } from "./Loader";
var LoaderContext = createContext({
    loading: false,
});
export var LoaderProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    useEffect(function () {
        var showLoader = function () { return setLoading(true); };
        var hideLoader = function () { return setLoading(false); };
        window.addEventListener("show-loader", showLoader);
        window.addEventListener("hide-loader", hideLoader);
        return function () {
            window.removeEventListener("show-loader", showLoader);
            window.removeEventListener("hide-loader", hideLoader);
        };
    }, []);
    return (React.createElement(LoaderContext.Provider, { value: { loading: loading } },
        loading && React.createElement(Loader, null),
        children));
};
