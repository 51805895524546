import React from "react";
import "./Loader.scss";
import logo from '../../logo.svg';
export var Loader = function () {
    return (React.createElement("div", { className: "loader-container" },
        React.createElement("img", { src: logo, alt: "Nissan Logo", className: "loader-logo" }),
        React.createElement("p", { className: "loader-text" }, "Logging out..."),
        React.createElement("div", { className: "spinner-container" },
            React.createElement("div", { className: "spinner" }))));
};
